@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
/* Nastaliq fonts for urdu */
@import url('https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    ::-webkit-scrollbar {
        height: 5px;
    }

    .floating-input>input::placeholder {
        color: transparent;
    }

    .floating-input>input:focus,
    .floating-input>input:not(:placeholder-shown) {
        @apply pt-8
    }

    .floating-input>input:focus~label,
    .floating-input>input:not(:placeholder-shown)~label {
        @apply opacity-75 scale-75 -translate-y-3 translate-x-1;
    }

    .floating-textarea>textarea::placeholder {
        color: transparent;
    }

    .floating-textarea>textarea:focus,
    .floating-textarea>textarea:not(:placeholder-shown) {
        @apply pt-8
    }

    .floating-textarea>textarea:focus~label,
    .floating-textarea>textarea:not(:placeholder-shown)~label {
        @apply opacity-75 scale-75 -translate-y-3 translate-x-1;
    }

}

.small-peoduct-slider .slick-vertical .slick-list.draggable {
    height: 53.3vh !important;
}

.small-peoduct-slider .slick-slide {
    margin-bottom: 3px !important;
}

.small-peoduct-slider .productImage svg {
    width: 100%;
    height: 34px;
}

.small-peoduct-slider .productImage {
    width: 100%;
}

@media only screen and (max-width:767px) {
    .slick_prev {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px
    }

    .slick_next {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px
    }
}

html {
    scroll-behavior: smooth;
}
